@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard.min.css");

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 배경을 반투명하게 설정합니다. */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* 모달을 최상위로 올립니다. */
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 1000px; /* 가로 크기를 1000px로 설정합니다. */
  height: 800px; /* 세로 크기를 800px로 설정합니다. */
  overflow-y: auto; /* 내용이 넘칠 경우 스크롤 표시 */
  position: relative; /* 모달 내부의 절대 위치 요소들을 정렬하기 위해 position을 relative로 설정합니다. */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 2); /* 그림자 효과 추가 */
  overflow-y: hidden;
  overflow-x: hidden;
}

.modal-scroll {
  overflow-y: auto;
}

.button-group {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.button-process {
  bottom: -20px; /* 아래에서 20px만큼 떨어진 위치 */
  position: relative;
  cursor: pointer;
  font-size: 16px;
  font-family: "Pretendard", sans-serif;
  background-color: #405189; /* 버튼 배경색 */
  color: white; /* 버튼 글자색 */
  padding: 10px 20px; /* 버튼 내부 패딩 설정 */
  border: none; /* 테두리 제거 */
  border-radius: 5px; /* 모서리 둥글게 만듦 */
  margin-bottom: 50px;
}

.button-close {
  bottom: -20px; /* 아래에서 20px만큼 떨어진 위치 */
  position: relative;
  cursor: pointer;
  font-size: 16px;
  font-family: "Pretendard", sans-serif;
  background-color: #f44336; /* 버튼 배경색 */
  color: white; /* 버튼 글자색 */
  padding: 10px 20px; /* 버튼 내부 패딩 설정 */
  border: none; /* 테두리 제거 */
  border-radius: 5px; /* 모서리 둥글게 만듦 */
  margin-bottom: 50px;
}

.button:hover {
  background-color: #d32f2f; /* 마우스 호버 시 배경색 변경 */
}
