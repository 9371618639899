@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard.min.css");

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; /* 요소들을 왼쪽으로 정렬 */
  
}

.card-con{
  display: flex;
  flex-direction: column; /* 요소들을 세로 방향으로 배열 */
  margin-bottom: 10px; 
}
.card-row {
  display: flex;
  margin-top: 10px; 
}
.card {
  width: 220px;
  height: 220px;
  background-color: transparent; /* 이미지 배경색 투명으로 설정 */
  border-radius: 20px;
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.network-card {
  width: 10vw;
  height: 10vh;
  background-color: transparent; /* 이미지 배경색 투명으로 설정 */
  border-radius: 1.5vh;
}


.card img {
  display: block; /* 이미지를 블록 요소로 설정하여 여백 제거 */
  margin: 0; /* 이미지 주위의 마진을 제거 */
  border-radius: 10px; /* 이미지에도 테두리 반경 적용 */
}

.card-content {
  position: relative;
}

.card-text {
  position: absolute;
  white-space: nowrap;
  top: 15px; /* 이미지 위에서 10px 떨어진 위치 */
  left: 15px; /* 이미지 왼쪽에서 10px 떨어진 위치 */
  color: #424242; /* 텍스트 색상 */
  font-size: 20px; /* 텍스트 크기 */
  font-weight: bold; /* 텍스트 굵기 설정 */
  font-family: "Pretendard", sans-serif;
}

.monitoring-text {
  font-weight: bold; /* 텍스트 굵기 설정 */
  font-family: "Pretendard", sans-serif;
  
  
}

.GREEN {
  border: 1vh solid #b0ffa2; /* 녹색 테두리 */
}

.YELLOW {
  border: 1vh solid #ffff6b; /* 노란색 테두리 */
}

.RED {
  border: 1vh solid #f44336; /* 빨간색 테두리 */
}
.BLUE {
  border: 1vh solid #405189;
}
.circle {
  position: absolute; /* 절대적 위치 설정 */
  top: 5px; /* 원하는 위치 설정 */
  right: 5px; /* 원하는 위치 설정 */
  width: 20px; /* 숫자 영역의 너비 */
  height: 20px; /* 숫자 영역의 높이 */
  border-radius: 50%; /* 원 모양으로 만들기 */
  background-color: #f44336; /* 빨간색 */
  color: white; /* 흰색 텍스트 */
  text-align: center; /* 가운데 정렬 */
  line-height: 20px; /* 수직 가운데 정렬 */
  font-size: 15px; /* 숫자 크기 설정 */
  font-weight: bold; /* 텍스트 굵기 설정 */
}

.logout-button {
  cursor: pointer;
  font-size: 14px;
  font-family: "Pretendard", sans-serif;
  background-color: #405189; /* 버튼 배경색 */
  color: white; /* 버튼 글자색 */
  padding: 10px 10px;
  border: none; /* 테두리 제거 */
  border-radius: 5px; /* 모서리 둥글게 만듦 */
  margin-left: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  
}
.serverdiv {
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.network-button {
  text-align: center;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;
  font-family: "Pretendard", sans-serif;
  background-color: #405189; /* 버튼 배경색 */
  color: white; /* 버튼 글자색 */
  padding: 10px 10px;
  border: none; /* 테두리 제거 */
  border-radius: 5px; /* 모서리 둥글게 만듦 */
  display: flex;
  width: 85px;
  height: 40px;
  margin-left: auto;
  margin-bottom: 1vh;
  margin-right: 1vh;
  align-items: center;
}
.center-button {
  cursor: pointer;
  font-size: 14px;
  font-family: "Pretendard", sans-serif;
  background-color: #405189; /* 버튼 배경색 */
  color: white; /* 버튼 글자색 */
  padding: 10px 10px;
  border: none; /* 테두리 제거 */
  border-radius: 5px; /* 모서리 둥글게 만듦 */
  
}
.network-group {
  margin-left: auto;
  display: flex;
}

.label-button {
  cursor: pointer;
  font-size: 14px;
  font-family: "Pretendard", sans-serif;
  background-color: #B4B4B4; /* 버튼 배경색 */
  color: white; /* 버튼 글자색 */
  padding: 10px 10px;
  border: none; /* 테두리 제거 */
  border-radius: 5px; /* 모서리 둥글게 만듦 */
  margin-left: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  
}


.day-button {
  cursor: pointer;
  font-size: 14px;
  font-family: "Pretendard", sans-serif;
  background-color: #405189; /* 버튼 배경색 */
  color: white; /* 버튼 글자색 */
  padding: 10px 10px;
  border: none; /* 테두리 제거 */
  border-radius: 5px; /* 모서리 둥글게 만듦 */
  margin-left: auto;
  margin-right: 1vh;
}
.white-box {
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  border-radius: 5px;
}